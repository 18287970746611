import React, { useState, useEffect, FC } from 'react';
import PromoBannerItem from '../PromoBannerItem/PromoBannerItem';
import promoImg1 from '../../assets/images/promo-1.png';
import promoImg2 from '../../assets/images/promo-2.png';
import s from './PromoBanner.module.scss';

export type Item = {
    image: string;
};

const items = [
    { image: promoImg1 },
    { image: promoImg2 },
];

const link = 'http://lk1.life.com.by/lp/flow/l_premium_playboy?lpid=98';

const PromoBanner: FC = () => {
    const [randomItem, setRandomItem] = useState<Item | null>(null);

    useEffect(() => {
        const getRandomItem = () => {
            const shuffledItems = [...items].sort(() => 0.5 - Math.random());
            setRandomItem(shuffledItems[0]);
        };

        getRandomItem();
    }, []);

    const handleClick = () => {
        window.location.replace(link);
    };
    return (
        <div
            onClick={handleClick}
            className={s.promoBanner}
            aria-hidden="true"
        >
            {randomItem && (
                <PromoBannerItem
                    image={randomItem.image}
                />
            )}
        </div>
    );
};

export default PromoBanner;
